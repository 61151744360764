<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="110px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="报告编号" :class="$i18n.locale">
              <el-input v-model="form.auditReportCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="稽查单号" :class="$i18n.locale">
              <el-input v-model="form.auditCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="采购订单号" :class="$i18n.locale">
              <el-input v-model="form.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="稽查地点" :class="$i18n.locale">
              <el-select
                v-model="form.auditPlace"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option label="仓库" value="仓库" />
                <el-option label="工厂" value="工厂" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale">
              <el-select
                v-model="form.vendorId"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style" :class="$i18n.locale">
              <el-select
                v-model="form.style"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报告人" :class="$i18n.locale">
              <el-select
                v-model="form.reportUser"
                clearable
                collapse-tags
                multiple
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in auditUserList"
                  :key="item.id"
                  :label="item.nickName"
                  :value="item.userName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态" :class="$i18n.locale">
              <el-select
                v-model="form.status"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option v-for="item in _getAllCommodityDict('AUDIT_REPORT_STATUS')" :key="item.val" :label="item.label" :value="item.val" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="缺失保函" :class="$i18n.locale">
              <el-checkbox v-model="form.isLackGuarantee" true-label="0" false-label="1" />
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="报告生成日期">
              <el-date-picker
                v-model="form.value1"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width: 15%">
        <el-col class="row-center">
          <el-button type="primary" class="ml-4" :loading="reportTableDatasLoading" @click="queryClick(1)">{{ $t("page.search") }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRow=!showRow">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="10">
      <el-col :span="6">
        <ImportButton export-key="scm_audit_report_export" :params="exportParams" :btn-type="'primary'" :is-disabled="idsList.length===0" />
      </el-col>
    </el-row>
    <el-table
      ref="reportTable"
      class="mt-3 mb-3"
      :row-class-name="tableRowClassNames"
      :loading="reportTableDatasLoading"
      :data="reportTableDatas"
      highlight-current-row
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="50"
      />
      <el-table-column
        type="index"
        width="50"
        label="序号"
      />
      <el-table-column
        property="auditReportCode"
        label="报告编号"
        min-width="200"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            :class="scope.row.isLackGuarantee===0?'warning-row':''"
            @click="handleClick(scope.row)"
          >{{ scope.row.auditReportCode }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        property="auditCode"
        label="稽查单号"
        min-width="110"
      />
      <el-table-column
        property="generateDate"
        label="报告生成日期"
        min-width="110"
      />
      <el-table-column
        property="auditPlace"
        label="稽查地点"
        min-width="110"
      />
      <el-table-column
        property="vendorName"
        label="供应商"
        min-width="110"
      />
      <el-table-column
        property="purchaseOrderCode"
        label="采购订单号"
        min-width="100"
      />
      <el-table-column
        property="style"
        label="Style"
        min-width="110"
      />
      <el-table-column
        property="shoeBatchNo"
        label="鞋印批次号"
        min-width="100"
      />
      <el-table-column
        property="inspectTotalPairs"
        label="报检总双数"
        min-width="90"
      />
      <el-table-column
        property="auditTotalPairs"
        label="稽查总双数"
        min-width="100"
      />
      <el-table-column
        property="auditRate"
        label="稽查占比%"
        min-width="100"
      />
      <el-table-column
        property="unqualifiedPairs"
        label="不合格双数"
        min-width="100"
      />
      <el-table-column
        property="sampleUnqualifiedRate"
        label="抽样不良占比%"
        min-width="120"
      />
      <el-table-column
        label="整批稽查结论"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ getAuditTypeLabel('AUDIT_RESULT',scope.row.auditResult) }}
        </template>
      </el-table-column>
      <!-- 结论为保函接收飘红，上传保函申请单后取消飘红 -->
      <el-table-column
        property="unqualifiedHandleResult"
        label="不合格处理结论"
        min-width="120"
      >
        <template slot-scope="scope">
          {{ getAuditTypeLabel('UNQUALIFIED_HANDLE_RESULT',scope.row.unqualifiedHandleResult) }}
        </template>
      </el-table-column>
      <el-table-column
        property="reportUser"
        label="报告人"
        min-width="100"
      />
      <el-table-column
        property="approvalUser"
        label="核准人"
        min-width="100"
      />
      <el-table-column
        property="date"
        label="状态"
        min-width="90"
      >
        <template slot-scope="scope">
          {{ getAuditTypeLabel('AUDIT_REPORT_STATUS',scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="purchasePersonnel"
        label="操作"
        fixed="right"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="handleClick(scope.row)"
          >查看</el-button>
          <!-- 待核准 -->
          <el-button v-if="scope.row.status==0" type="text" size="small" @click="shipmentClick(scope.row)">核准</el-button>
          <!-- 已核准 -->
          <el-button v-if="scope.row.status==1" type="text" size="small" @click="uploadClick(scope.row)">补传保函申请单</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>
    <el-dialog
      title="稽查检验报告核准"
      :visible.sync="dialogVisible"
      width="550px"
    >
      <el-form ref="approvalAuditReport" :model="approvalAuditReportForm" label-width="120px" :rules="approvalAuditReportFormRules">
        <el-form-item label="整批稽查结论" prop="auditResult">
          <el-radio-group v-model="approvalAuditReportForm.auditResult">
            <el-radio v-for="item in _getAllCommodityDict('AUDIT_RESULT')" :key="item.val" :label="item.val">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="approvalAuditReportForm.auditResult==2" label="不合格处理结论" prop="unqualifiedHandleResult">
          <el-radio-group v-model="approvalAuditReportForm.unqualifiedHandleResult">
            <el-radio v-for="item in _getAllCommodityDict('UNQUALIFIED_HANDLE_RESULT')" :key="item.val" :label="item.val">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="approvalAuditReportForm.remark" type="textarea" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleSure">核 准</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="补传保函申请单"
      :visible.sync="repostingDialogVisible"
      width="500px"
    >
      <el-form label-width="130px">
        <el-form-item label="附件" required>
          <el-upload
            ref=""
            :headers="uploadHeaders"
            :action="action"
            :limit="1"
            class="upload-demo"
            :before-upload="beforeUpload"
            :on-success="lfoahandleSuccess"
            :on-remove="lfoahandleRemove"
            :file-list="lfoafilelist"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="repostingDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="reissueGuarantee">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryStyleList, queryVendorList } from '@/api/scm-api'
import { approvalAuditReport, reissueGuarantee, auditReportPage, listUserByRoleCode } from '@/api/audit-api'
import Paging from '@/components/Pagination'
import { deepClone } from '@/utils'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import ImportButton from '@/components/ExportFile'

export default {

  components: { Paging, ImportButton },
  mixins: [commodityInfoDict],
  data() {
    return {
      auditUserList: [],
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      guaranteeReleaseBill: '',
      approvalAuditReportFormRules: { auditResult: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        unqualifiedHandleResult: [{ required: true, message: this.$t('page.required'), trigger: 'change' }] },
      approvalAuditReportForm: {
        auditResult: ''
      },
      lfoafilelist: [],
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      action: process.env.VUE_APP_SCM_API + 'scm-purchase/basic/uploadFile',
      row: {},
      repostingDialogVisible: false,
      showRow: false,
      form: {
        value1: [],
        vendorId: [],
        status: [],
        style: [],
        reportUser: []
      },
      styleOptions: [],
      vendorOptions: [],
      reportTableDatas: [],
      reportTableDatasLoading: false,
      dialogVisible: false,
      idsList: []
    }
  },
  computed: {
    approvalAuditReportParams() {
      const { auditReportCode } = this.row
      const { auditResult, remark } = this.approvalAuditReportForm
      if (auditResult === 1) {
        return Object.assign({}, { auditReportCode, auditResult, remark })
      } else {
        return Object.assign({}, { auditReportCode }, this.approvalAuditReportForm)
      }
    },
    auditReportPageParams() {
      const obj = deepClone(this.form)
      const [startGenerateDate, endGenerateDate] = obj.value1 || []
      const { vendorId, style, status, reportUser } = obj
      delete obj.value1
      return Object.assign({}, { startGenerateDate, endGenerateDate }, this.pager, obj, { vendorId: String(vendorId) || '', style: String(style) || '', status: String(status) || '', reportUser: String(reportUser) || '' })
    },
    exportParams() {
      return Object.assign({}, { idsList: this.idsList, i18n: this.$i18n.locale === 'en' ? 'en-US' : 'zh-CN' })
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (!val) {
        this.$refs.approvalAuditReport.resetFields()
        this.approvalAuditReportForm = {}
      }
    }
  },
  mounted() {
    this._queryStyleList()
    this._queryVendorList()
    this.queryClick(1)
    this._listUserByRoleCode('audti_user')
  },
  methods: {
    handleSelectionChange(val) {
      this.idsList = []
      val.map(item => this.idsList.push(item.id))
    },
    async _listUserByRoleCode(code) {
      const { code: Code, datas } = await listUserByRoleCode(code)
      if (Code === 0) {
        this.auditUserList = datas
      }
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.queryClick(1)
    },
    tableRowClassNames({ row, rowIndex }) {
      if (row.isLackGuarantee === 0) {
        return 'warning-row'
      }
      return ''
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick(0)
    },
    async queryClick(flag) {
      try {
        this.reportTableDatasLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await auditReportPage(this.auditReportPageParams)
        this.pager = pager
        this.reportTableDatas = records.map(item => {
          return { ...item, auditRate: item.auditRate ? item.auditRate * 100 : 0, sampleUnqualifiedRate: item.sampleUnqualifiedRate ? item.sampleUnqualifiedRate * 100 : 0 }
        })
        this.reportTableDatasLoading = false
      } catch (err) { console.log(err) } finally {
        this.reportTableDatasLoading = false
      }
    },
    async reissueGuarantee() {
      if (!this.guaranteeReleaseBill) {
        this.$message.warning('请上传附件')
        return
      }
      const { code, msg } = await reissueGuarantee({ auditReportCode: this.row.auditReportCode, guaranteeReleaseBill: this.guaranteeReleaseBill })
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.repostingDialogVisible = false
      }
    },
    dialogVisibleSure() {
      this.$refs.approvalAuditReport.validate(async valid => {
        if (valid) {
          const { code, msg } = await approvalAuditReport(this.approvalAuditReportParams)
          if (code === 0) {
            this.$notify({
              title: msg,
              message: msg,
              type: 'success'
            })
            this.dialogVisible = false
            this.queryClick(1)
          }
        }
      })
    },
    lfoahandleSuccess({ code, datas }, filelist) {
      if (code === 0) {
        this.guaranteeReleaseBill = datas
        this.$message.success('上传成功')
      }
    },
    lfoahandleRemove(file) {
      this.guaranteeReleaseBill = ''
    },
    beforeUpload(file) {
      // const Xls = file.name.split('.')
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error('不能超过10M')
        return false
      } else {
        return true
      }
    },
    uploadClick(row) {
      this.row = row
      this.repostingDialogVisible = true
    },
    handleClick(row) {
      this.$router.push({
        path: 'add',
        append: 'true',
        query: { 'id': row.id, flag: 'report' }
      })
    },
    handleDetails() {},
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    shipmentClick(row) {
      this.row = row
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
  .el-range-input {
    width: 90px !important;
  }
  .el-range-separator {
    width: 6% !important;
  }
  .el-date-editor {
    width: 100% !important;
  }
  /deep/ .warning-row {
  color: red !important;
}
</style>
